<template>
  <div>
    <VTitle :title="$t('app.usage')" class="section-title" />

    <TableCard
      display-pagination
      :headers="headers"
      :is-loading="isLoading"
      :rows="displayedRows"
      :pagination="pagination"
      @update:current_page="onUpdateCurrentPage"
    />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useCompetency from "@/composables/useCompetency";
import useReadMultiple from "@/composables/useReadMultiple";
import usePaginate from "@/composables/usePaginate";
import useTexts from "@/composables/useTexts";
// Components
import TableCard from "@/components/tables/TableCard";
import VTitle from "@/components/VTitle";

export default {
  components: {
    TableCard,
    VTitle
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    competency: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Data
    const { endpoint } = useCompetency();
    const { data, pagination, isLoading, read } = useReadMultiple();
    const { getText } = useTexts();

    // Computed
    const headers = computed(() => {
      return [
        {
          text: t("app.name"),
          value: "name"
        },
        {
          text: t("app.type"),
          value: "resource_type"
        }
      ];
    });

    const displayedRows = computed(() => {
      const keys = Object.keys(data.value);

      if (keys.length === 0) {
        return [];
      }

      return keys.reduce((acc, key) => {
        const values = data.value[key].map(x => ({
          ...x,
          name: getText(x?.texts, "name"),
          resource_type: t(`app.${key}`, 2)
        }));

        return [...acc, ...values];
      }, []);
    });

    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.usage")} - ${t("app.competencies", 2)} - ${t("app.teaching")}`;

      const name = getText(props.competency?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: `${endpoint}.usage`,
        pathParams: {
          id: props.id
        }
      });
    };

    const { onUpdateCurrentPage } = usePaginate(pagination, getData);

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      headers,
      displayedRows,
      // usePaginate
      onUpdateCurrentPage,
      // useReadMultiple
      pagination,
      rows: data,
      isLoading
    };
  }
};
</script>
